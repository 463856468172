<template>
  <div id="TransferenciaCriacao">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title id="topo" v-bind:style="{ 'color':COR_SUBTITULO }" class="body-1 mt-1 font-primary ml-2 mb-4 d-flex align-center justify-space-between">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
      Transferências
      </p>

      <v-btn @click="novo()" class="btn mr-1 pr-6 " elevation="0">
        <v-icon class="btn-icon mr-1" color="green accent-4">mdi-plus </v-icon>
        <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Novo</span>
      </v-btn>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <v-form
      v-model="valid"
      ref="formEditar"
      class="mx-1 mt-n5"
      lazy-validation>
     
      <v-container class="">
        <v-row class="pt-0">
          <!-- PRIMEIRA COLUNA / SITUACAO ------------------------>
          <v-col
            v-if="store_Proposta.acao != 'I'"
            cols="12"
            sm="12"
            class="px-2 pb-0 pt-0 mb-1">
            <span>
              Selecione a transferência para ver os demais dados e assim se precisar editá-los
            </span>
          </v-col>
          <!-- <pre>{{ selected }}</pre> -->
          <!-- <pre>{{ store_Proposta.proposta_selecionado.emp_ve_transf[0] }}</pre> -->
          <!-- <pre>{{ store_Proposta.proposta_selecionado.emp_ve_transf[1] }}</pre> -->
          <v-col
            cols="12"
            sm="12"
            class="py-0 pl-0">
            <v-autocomplete
              v-if="store_Proposta.acao == 'C'"
              v-model="selected"
              @change="ARQUIVO = '';"
              label="Selecione as transferências"
              placeholder="Selecione as transferências"
              filled
              outlined
              dense
              background-color="#FFF"
              :items="store_Proposta.proposta_selecionado.emp_ve_transf"
              :item-text = "formatItemText"
              item-value = "cod_empreendvenda_transf"
              return-object />
          </v-col>
          <!-- FIM PRIMEIRA COLUNA / SITUACAO ------------------------>
        </v-row>

        <v-row v-if="!!selected.cod_empreendvenda_transf || !!selected.cod_empreendvenda || store_Proposta.acao == 'I'">
          <v-col cols="12" class="pa-0">
            <v-card >
              <v-card-title 
								class="font-primary font-weight-bold font-weight-medium body-1 d-flex justify-space-between" 
								:style="{ 'color':COR_SUBTITULO }">
                 <span>Dados da Transferência</span>
                  <div
                    v-if="selected.cod_empreendvenda_transf == mostrarBotaoEditar()">
                    <v-btn
                      v-if="(store_Proposta.acao == 'C') && (Object.keys(selected).length != 0 )"
                      @click="transfAux()"
                      class="btn mr-1 pr-6 "
                      elevation="0">
                      <v-icon
                        class="btn-icon mr-1"
                        color="green accent-4">
                        mdi-pencil-outline
                      </v-icon>
                      <span
                        class="caption font-weight-medium"
                        :style="{ 'color':COR_SUBTITULO }">
                        Editar
                      </span>
                    </v-btn>
                    <v-btn
                      v-if="(store_Proposta.acao == 'C') && (Object.keys(selected).length != 0 )"
                      @click="dialog_excluir = true; "
                      class="btn mr-1 pr-6 "
                      elevation="0">
                      <v-icon
                        class="btn-icon mr-1"
                        color="red accent-4">
                        mdi-delete-outline
                      </v-icon>
                      <span
                        class="caption font-weight-medium"
                        :style="{ 'color':COR_SUBTITULO }">
                        Deletar
                      </span>
                    </v-btn>
                  </div>
              </v-card-title>
              <v-card-text>
  
                <v-row class="pt-2">
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-n4 px-2 pb-0">
                    <v-text-field
                      v-model="selected.cedente"
                      light
                      loader-height="1"
                      background-color="#FFF"
                      label="Cedente"
                      placeholder="Cedente"
                      outlined
                      required
                      readonly
                      dense>
                      <!-- <template v-slot:append-outer >
                        <v-icon
                          @click="abreDialogoCedente()"
                          color="primary"
                          :disabled="store_Proposta.acao == 'C'">mdi-magnify</v-icon>
                      </template> -->
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-n4 px-2 pb-0">
                    <v-text-field
                      v-model="selected.cessionario"
                      light
                      loader-height="1"
                      background-color="#FFF"
                      label="Cessionário"
                      placeholder="Cessionário"
                      outlined
                      required
                      readonly
                      dense>
                      <!-- <template v-slot:append-outer >
                        <v-icon @click="abreDialogoCessionario()" color="primary" :disabled="store_Proposta.acao == 'C'"
                        >mdi-magnify</v-icon>
                      </template> -->
                    </v-text-field>
                  </v-col>
                
								</v-row>
                <v-row class=" mt-0">
                  <v-col
                    cols="12"
                    sm="4"
                    class="px-2 pb-0">
                    <DatePicker
                      v-model="selected.empreendtransf_dt"
                      label="Data"
                      placeholder="dia/mês/ano"
                      class="mr-0 "
                      outlined
                      backgroundColor="#FFF"
                      tabindex="0"
                      :rules="dataTransferenciaRules"
                      :readonly="store_Proposta.acao == 'C'"
                      dense
                      format="DD/MM/YYYY">
                    </DatePicker>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    class="px-2 pb-0">
                    <v-text-field 
                      v-model="selected.empreendtransf_acerto_vl" 
                      class="mr-0 "
                      background-color="#FFF" 
                      label="Valor do Acerto"
                      :readonly="store_Proposta.acao == 'C'" 
                      light 
                      filled 
                      type="number"
                      outlined 
                      dense />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="5"
                      class="px-2 pb-0">
                      <v-select 
                        v-model="selected.empreendtransf_doc_situacao" 
                        class="mr-0 "
                        background-color="#FFF" 
                        label="Documentação está em ORDEM:" 
                        placeholder="Documentação está em ORDEM:" 
                        auto-select-first 
											  :readonly="store_Proposta.acao == 'C'" 
                        light 
                        filled 
                        outlined 
                        required 
                        dense
                        :items="arraySIM_NAO">
                      </v-select>
                    </v-col>
                  </v-row>
                <v-row class="mt-0">
									<v-col
										cols="12"
										sm="12"
										class="px-2 pb-0">
										<v-textarea 
											v-model="selected.empreendtransf_obs" 
											rows="1" 
											auto-grow 
											counter="255" 
											:readonly="store_Proposta.acao == 'C'" 
											loader-height="1" 
											background-color="#FFF" 
											label="Observação:" 
											placeholder="Observação:" 
											light 
											filled 
											outlined 
											required 
											dense />
									</v-col>
									
								</v-row>
                <v-row class="mt-0" v-if="store_Proposta.acao == 'C'">
                  <v-col
                    cols="12"
										sm="12"
										class="px-2 pb-0">
                    <!-- CONTAINER EDITOR -------------------------------------------------------->
                    <v-container class="mx-0 pa-0 container" v-if="store_Proposta.acao == 'C'">
                      <v-card elevation="0" class="card rounded-lg mx-0 px-0">
                        <template>
                          <v-toolbar
                          v-if="selected.cod_empreendvenda_transf == mostrarBotaoEditar()"
                            flat
                            class="rounded-lg d-flex justify-end">
                            <v-btn 
                              @click="dialog_modelo = true"
                              class="mr-4 pr-6" elevation="0">
                              <v-icon
                                class="btn-icon mr-1"
                                :style="{ 'color':COR_SUBTITULO }">mdi-sync-circle</v-icon>
                              <span
                                class="caption font-weight-medium"
                                :style="{ 'color':COR_SUBTITULO }">
                                Remontar Documento
                              </span>
                            </v-btn>

                            <v-btn 
                              v-if="selected.transferencia.length > 0"
                              @click="AbrirDocumentoEditor()"
                              class="pr-6" 
                              elevation="0">
                              <v-icon
                                class="btn-icon mr-1"
                                :style="{ 'color':COR_SUBTITULO }">
                                mdi-pencil
                              </v-icon>
                              <span
                                class="caption font-weight-medium"
                                :style="{ 'color':COR_SUBTITULO }">
                                Editar Documento
                              </span>
                            </v-btn>

                            <v-spacer></v-spacer>
                          </v-toolbar>
                        </template>

                        <!-- EDITOR -------------------------------------------------------->
                        <v-card-text class="pt-0" v-if="ARQUIVO && selected.transferencia.length > 0">
                          <!-- {{ ARQUIVO }} -->
                          <div style="background: #FFFFFF; width: 100%; ">

                            <EditorDocumento
                              :ACAO="ACAO"
                              :ARQUIVO="ARQUIVO"
                              :TIPO="TIPO"
                              :TIPO_VISUALIZACAO="TIPO_VISUALIZACAO"
                              :TITULO="TITULO"
                              :USER_ADDRESS="USER_ADDRESS"
                              :URL_API="URL_API"
                              :CAMINHO="CAMINHO"
                            />

                            <!-- <iframe frameBorder="0" width='100%' height='1000' src="https://docs.google.com/document/d/1KdFYg7C3qIAZKW-qnmLfpHyll6U7JUPqt0yP6G63pUM/edit#heading=h.6jynaot9cbnq/pub?embedded=true"></iframe>-->
                            <!-- https://codepen.io/Eazymov/full/MEzGYv/ -->
                            <!-- https://openbase.com/js/vue2-editor -->
                            <!-- https://github.surmon.me/vue-quill-editor/ -->
                            <!-- https://openbase.com/js/@toast-ui/vue-editor#-examples -->
                            <!-- http://hifarer.github.io/vueditor/ -->
                            <!-- https://donotebase.github.io/quasar-tiptap/examples/all -->
                            <!-- https://openbase.com/js/mavon-editor -->
                            </div>
                        </v-card-text>
                        <!-- FIM EDITOR -------------------------------------------------------->
                      </v-card>
                    </v-container>
                    <!-- FIM CONTAINER EDITOR -------------------------------------------------------->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

          </v-col>
        </v-row>

        <v-footer v-if="store_Proposta.acao != 'C'" 
          color="transparent" 
          elevation="0" 
          class="footer justify-center mt-3 pt-3">
            <v-btn @click="cancelar()" 
              id="btn_cancelar" 
              ref="btn_cancelar" 
              class="mr-4 caption font-weight-medium" 
              color="primary" 
              text>
            Cancelar
          </v-btn>

          <v-btn
            :loading="loading"
            :disabled="!valid"
            @click="validate()"
            class="btn caption font-weight-medium white--text"
            color="primary accent-4">
            Salvar
          </v-btn>
        </v-footer>

      </v-container>  
    </v-form>
    <!-- <div class="container">
      <v-row class="mx-0">
        <v-col cols="12" class="d-flex flex-column">
          <span>
            Temporibus aspernatur et libero esse est voluptatem minima. Occaecati ratione quis maxime et et rerum. Eum animi ut dolore tenetur numquam numquam corrupti eligendi. Saepe dolore ex ea porro impedit mollitia iste qui. Quod eveniet labore.
          </span>
        </v-col>

        <v-col class="d-flex">
          <v-btn
          @click="store_Proposta.dialogTransferenciaCriacao = true" 
          class="caption font-weight-medium white--text mx-auto"
          color="primary accent-4"
          >
            Criar Transferência
          </v-btn>
        </v-col>
      </v-row>
    </div> -->

    <!-- <v-dialog
      v-model="store_Proposta.dialogTransferenciaCriacao"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
      <TransferenciaCriacaoModal />
    </v-dialog> -->

    
    <!-- DIALOGO CESSIONARIO -------------------------------------------------------->
    <v-container v-if="dialogCessionario">      
      <v-dialog
        v-model="dialogCessionario"
        scrollable
        max-width="500px"
        max-height="600px">
        <v-card>
          <!-- Cabecalho da PESSOA -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Cessionário
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="dialogCessionario = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do PESSOA -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile
              class="mt-n2"
              :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do PESSOA -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="dialogCessionario = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="selecionarCessionario(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO CESSIONARIO ---------------------------------------------------->

    <!-- DIALOGO CESSIONARIO -------------------------------------------------------->
    <v-container v-if="dialogNovoCessionario">      
      <v-dialog
        v-model="dialogNovoCessionario"
        scrollable
        max-width="500px"
        max-height="600px">
        <v-card>
          <!-- Cabecalho da PESSOA -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Cessionário
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="dialogNovoCessionario = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do PESSOA -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile
              class="mt-n2"
              :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do PESSOA -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="dialogNovoCessionario = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="selecionarNovoCessionario(store_Pessoa.pessoa_selecionado)"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO CESSIONARIO ---------------------------------------------------->

    <!--------- DIALOG EXCLUIR TRANSFERENCIA ------------------------------->
    <!-- <v-container v-if="dialog_excluir">
      <v-dialog v-model="dialog_excluir" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
        <v-divider></v-divider>
  
        <v-card elevation="0" class="">
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja EXCLUIR esta transferência?
          </v-card-title>
          <v-card-text>
           
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir = false">
              Cancelar
            </v-btn>
  
            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="dialog_excluir = false; dialog_excluir2 = true">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container> -->
    <!--------- FIM DIALOG EXCLUIR TRANSFERENCIA ------------------------------->

    <!--------- DIALOG EXCLUIR TRANSFERENCIA ------------------------------->
    <v-container v-if="dialog_excluir2">
      <v-dialog v-model="dialog_excluir2" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
        <v-divider></v-divider>
  
        <v-card elevation="0" class="">
          <v-card-title class="text-h6" style="word-break: keep-all">
            Esta operação é IRREVERSÍVEL, deseja continuar mesmo assim?
          </v-card-title>
          <v-card-text>
            <!-- <span style="font-size: 15px; font-weight:600">{{store_Pessoa.pessoa_selecionado.pessoa_nome}}</span><br> -->
            <!-- Tipo: {{tipo_Aux}}<br> -->
            <!-- Data cadastro: {{cadastro_dt_Aux}}<br> -->
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir2 = false">
              Cancelar
            </v-btn>
  
            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="excluir()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!--------- FIM DIALOG EXCLUIR TRANSFERENCIA ------------------------------->

    <!--------- DIALOG modelo ------------------------------->
    <v-container v-if="dialog_modelo">
      <v-dialog v-model="dialog_modelo"  max-width="440px">
      <v-card v-bind:style="{ 'background-color':COR_SECUNDARIA }" class="container pa-0">
        <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
          <span class="text-white title-page">Novo Documento</span>
          <v-btn @click="dialog_modelo = false" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="wrapper pt-1 px-0 pb-13">
          <v-container class="px-7">
            <v-row>
              <v-col class="pb-0 px-0">
                <v-form v-model="valid" ref="formModelo">
                  <v-autocomplete 
                    label="Modelo" 
                    v-model="modelo_escolhido" 
                    filled 
                    light 
                    dense 
                    return-object
                    outlined
                    background-color="#FFF" 
                    :items="array_modelos" 
                    required
                    :rules="modeloRules"
                    item-value="cod_modelo_documento"
                    item-text="titulo">
                  </v-autocomplete>

                </v-form>
              </v-col>
            </v-row>
          </v-container>

          <v-divider class="mx-4 mb-2"></v-divider>

        </v-card-text>

        <v-footer v-bind:style="{ 'background-color':COR_SECUNDARIA }" absolute elevation="0" class="d-flex justify-center pa-3">
          <v-btn @click="dialog_modelo = false" class="mr-4 btn caption font-weight-medium" color="primary" text>
            Cancelar
          </v-btn>

          <v-btn 
            :disabled="!valid" 
            @click="montarModelo()" 
            class="btn white--text caption font-weight-medium" 
            :loading="loading" 
            color="primary accent-4">
            Salvar
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>
    </v-container>
    <!--------- FIM DIALOG modelo ------------------------------->

    <!-- MODAL NOVA TRANSFERENCIA -->
    <v-container v-if="dialog_novo">
      <v-dialog v-model="dialog_novo"  max-width="440px" persistent>
      <v-card v-bind:style="{ 'background-color':COR_SECUNDARIA }" class="container pa-0">
        <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
          <span class="text-white title-page">Nova Transferência</span>
          <v-btn @click="dialog_novo = false" icon dark color="#F2F6F7">
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="wrapper pt-1 px-0 pb-13">
          <v-container class="px-4">
            <v-form v-model="valid" ref="formNovaTransf">
              <v-row >
                <v-col
                  cols="12"
                  sm="12"
                  class="pt-n4 px-2 pb-0">
                  <v-text-field
                    v-model="nova_transf.cedente"
                    light
                    loader-height="1"
                    background-color="#FFF"
                    label="Cedente"
                    placeholder="Cedente"
                    outlined
                    required
                    readonly
                    :rules="cedenteRules"
                    dense>
                    <!-- <template v-slot:append-outer >
                      <v-icon
                        @click="abreDialogoNovoCedente()"
                        color="primary">
                        mdi-magnify
                      </v-icon>
                    </template> -->
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  class="pt-0 px-2 pb-0">
                  <v-text-field
                    v-model="nova_transf.cessionario"
                    light
                    loader-height="1"
                    background-color="#FFF"
                    label="Cessionário"
                    placeholder="Cessionário"
                    outlined
                    required
                    :rules="cessionarioRules"
                    readonly
                    dense>
                    <template v-slot:append-outer >
                      <v-icon
                        @click="abreDialogoNovoCessionario()"
                        color="primary">
                        mdi-magnify
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class=" mt-0">
                <v-col
                  cols="12"
                  sm="12"
                  class="px-2 pb-0">
                  <DatePicker
                    v-model="nova_transf.empreendtransf_dt"
                    label="Data"
                    placeholder="dia/mês/ano"
                    class="mr-0 "
                    outlined
                    backgroundColor="#FFF"
                    tabindex="0"
                    dense
                    :rules="dataTransferenciaRules"
                    format="DD/MM/YYYY">
                  </DatePicker>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  class="pt-0 px-2 pb-0">
                  <v-text-field 
                    v-model="nova_transf.empreendtransf_acerto_vl" 
                    class="mr-0 "
                    background-color="#FFF" 
                    label="Valor do Acerto"
                    light 
                    filled 
                    type="number"
                    outlined 
                    dense />
                  </v-col>
                </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  class="pt-0 px-2 pb-0">
                  <v-select 
                    v-model="nova_transf.empreendtransf_doc_situacao" 
                    class="mr-0 "
                    background-color="#FFF" 
                    label="Documentação está em ORDEM:" 
                    placeholder="Documentação está em ORDEM:" 
                    auto-select-first 
                    light 
                    filled 
                    outlined 
                    required 
                    dense
                    :items="arraySIM_NAO">
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  class="pt-0 px-2 pb-0">
                  <v-textarea 
                    v-model="nova_transf.empreendtransf_obs" 
                    rows="1" 
                    auto-grow 
                    counter="255" 
                    loader-height="1" 
                    background-color="#FFF" 
                    label="Observação:" 
                    placeholder="Observação:" 
                    light 
                    filled 
                    outlined 
                    required 
                    dense />
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-divider class="mx-4 mb-2"></v-divider>

        </v-card-text>

        <v-footer
          :style="{ 'background-color':COR_SECUNDARIA }"
          absolute
          elevation="0"
          class="d-flex justify-center pa-3">
          <v-btn
            @click="dialog_novo = false; "
            class="mr-4 btn caption font-weight-medium"
            color="primary"
            text>
            Cancelar
          </v-btn>

          <v-btn 
            :disabled="!valid" 
            @click="salvarNovaTransferencia()" 
            class="btn white--text caption font-weight-medium" 
            :loading="loading" 
            color="primary accent-4">
            Salvar
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>
    </v-container>
    <!-- FIM MODAL NOVA TRANSFERENCIA -->

     <!--------- DIALOG EXCLUIR TRANSFERENCIA ------------------------------->
     <v-container v-if="dialog_excluir">
      <v-dialog v-model="dialog_excluir" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
        <v-divider></v-divider>
  
        <v-card elevation="0" class="">
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja EXCLUIR esta transferência?
          </v-card-title>
          <v-card-text>
            <!-- <span style="font-size: 15px; font-weight:600">{{store_Pessoa.pessoa_selecionado.pessoa_nome}}</span><br> -->
            <!-- Tipo: {{tipo_Aux}}<br> -->
            <!-- Data cadastro: {{cadastro_dt_Aux}}<br> -->
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir = false">
              Cancelar
            </v-btn>
  
            <v-btn class="btn white--text" color="primary accent-4" :loading="loading" @click="dialog_excluir = false; dialog_excluir2 = true">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!--------- FIM DIALOG EXCLUIR TRANSFERENCIA ------------------------------->

    <!-- btn de rolar ao topo -->
    <v-btn
      :color="COR_PRINCIPAL"
      id="myBtn"
      @click="topFunction()"><v-icon
      color="white"
      size="x-large">mdi-chevron-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import store_Pessoa from "../../Pessoas/store_Pessoa";
import store_site from "../../../store/store_site";
import PessoaConsultaMobile from "../../Pessoas/PessoaConsultaMobile.vue"
import store_usuario from "../../../store/store_usuario";
import EditorDocumento from "../../ComponentesGlobais/EditorDocumentos/EditorDocumento.vue"
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO } from "../../../services/constantes";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import { API } from "../../../services/API"
import moment from 'moment';

export default {
  name: "TransferenciaCriacao",

  components: {
    PessoaConsultaMobile,
    DatePicker,
    EditorDocumento
  },

  data() {
    return {

      store_Proposta    : store_Proposta,
      store_site        : store_site,
      store_usuario     : store_Proposta,
      store_Pessoa      : store_Pessoa,
      COR_PRINCIPAL     : COR_PRINCIPAL,  
      COR_SECUNDARIA    : COR_SECUNDARIA,  
      COR_SUBTITULO     : COR_SUBTITULO,  
      API               : API,

      valid                 : false,
      planos                : {},
      selected              : [], 
      arraySIM_NAO          : ['SIM', 'NÃO'],
      loading               : false,
      edicao_aux            : null,
      dialog_excluir        :  false,
      dialog_excluir2       :  false,
      dialog_modelo         : false,
      dialog_novo           : false,
      dialogCedente         : false,
      dialogNovoCedente     : false,
      dialogCessionario     : false,
      dialogNovoCessionario : false,
      array_modelos         : [],
      modelo_escolhido      : null,
      nova_transf           : {
                                cedente: '',
                                cod_pessoa_cedente : null,
                                cessionario : '',
                                cod_pessoa_cessionario : null,
                              },

      ACAO              : 'ABRIR_DOCUMENTO',
      ARQUIVO           : null,
      USER_ADDRESS      : 'empresa_' + store_site.cod_empresa.toString().padStart(6, '0'),
      TIPO              : 'transferencia',
      TIPO_VISUALIZACAO : 'embedded',
      URL_API           : store_usuario.user.url_api,
      //URL_API           : 'http://192.168.8.100:30001',
      CAMINHO           : `empreend_venda/transferencia`,
      TITULO            : '',

      cedenteRules: [
        (value) => !!value || "O cedente é obrigatório",
      ],
      cessionarioRules: [
        (value) => !!value || "O cessionário é obrigatório",
      ],
      dataTransferenciaRules: [
        (value) => !!value || "A data é obrigatório",
      ],
      modeloRules: [
        (value) => !!value || "O modelo é obrigatório",
      ],
    };
  },

  updated(){
    if (this.selected?.transferencia?.length > 0 && Object.keys(this.selected).length !== 0) {
      this.TITULO = this.selected?.transferencia[0]?.filename
      this.ARQUIVO = this.selected?.transferencia[0]?.filename
    } 
    else{
      this.TITULO = ''
      this.ARQUIVO = ''
    }
  },

  mounted(){
    this.modelosGet()
  },

  methods:{
     // Abre Modelo Word e abre o Editor para edição
     AbrirDocumentoEditor() {
      const lo_Query = {  ACAO          : 'ABRIR_DOCUMENTO',
                          ARQUIVO       : this.ARQUIVO,
                          USER_ADDRESS  : this.USER_ADDRESS,
                          TIPO          : this.TIPO,
                          URL_API       : this.URL_API,
                          CAMINHO       : this.CAMINHO,
                          TITULO        : this.TITULO,                                                     
                        };
      // console.log('lo_Query:: ', lo_Query);
      const routeData = this.$router.resolve({ name   : 'EditorDocumento',
                                              query  : lo_Query });
      // Abre o editor em nova tela
      window.open(routeData.href, '_blank');
    },

    topFunction() {
      const elemento = document.querySelector('#topo');
      elemento.scrollIntoView({
        behavior: "smooth" // Define o comportamento de rolagem suave
      });
    },

    async excluir(){
      // this.loading = true;
      var ls_Res = await this.store_Proposta.TransferenciaDelete(
        { cod_empreendvendatransf_chave : this.selected.cod_empreendvendatransf_chave}
      );

      if (ls_Res.message == "success") {
        this.store_site.alert_cor = "#00A000";
        this.store_site.alert_timeout = 10000;
        this.store_site.alert_msg = ls_Res.result.trim();
        this.store_site.alert = true;

        // coloca no array o nome do antigo dono
        this.store_Proposta.proposta_selecionado.pessoa_venda.pessoa_nome = this.store_Proposta.proposta_selecionado.emp_ve_transf[0].cedente
        
        const i = this.store_Proposta.proposta_selecionado.emp_ve_transf.findIndex(
          element => element.cod_empreendvendatransf_chave == this.selected.cod_empreendvendatransf_chave
        )

        if (i !== -1) {
          this.store_Proposta.proposta_selecionado.emp_ve_transf.splice(i, 1)
        }
        this.selected = []

          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
        this.dialog_excluir2 = false
        this.store_Proposta.acao = 'C'
    },

    async validate() {

      const lb_valido = this.$refs.formEditar.validate();

      if (lb_valido){ 

        var lo_JSON = {
          cod_empreendvenda               : Number(this.$route.params.cod_empreendvenda),
          ...this.selected
        };
        if (lo_JSON.empreendtransf_acerto_vl == '') {
          lo_JSON.empreendtransf_acerto_vl = null
        }
      
        this.loading = true;
        var ls_Res = await this.store_Proposta.TransferenciaPut(lo_JSON);

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;
          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
        this.store_Proposta.acao = 'C'
      }
    },
    async salvarNovaTransferencia(){
      const lb_valido = this.$refs.formNovaTransf.validate();

      if (lb_valido){ 
        this.loading = true
        var lo_JSON = {
          cod_empreendvenda             : Number(this.$route.params.cod_empreendvenda),
          cod_empreendimento            : Number(this.store_Proposta.proposta_selecionado.cod_empreendimento),
          cod_pessoa_cedente            : this.nova_transf.cod_pessoa_cedente,
          cod_pessoa_cessionario        : this.nova_transf.cod_pessoa_cessionario,
          empreendtransf_dt             : this.nova_transf.empreendtransf_dt,
          empreendtransf_obs            : this.nova_transf.empreendtransf_obs,
          empreendtransf_acerto_vl      : this.nova_transf.empreendtransf_acerto_vl,
        }

        const ls_Res = await this.store_Proposta.TransferenciaPost(lo_JSON);

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          const retorno = ls_Res.result.data
          const primeira_posicao_array = {
            cod_empreendvenda_transf: retorno.cod_empreendvenda_transf,
            cod_empreendvenda: retorno.cod_empreendvenda,
            cod_pessoa_cedente: this.nova_transf.cod_pessoa_cedente,
            cedente: this.nova_transf.cedente,
            cod_pessoa_cessionario: this.nova_transf.cod_pessoa_cessionario,
            cessionario: this.nova_transf.cessionario,
            empreendtransf_dt: this.nova_transf.empreendtransf_dt,
            empreendtransf_modelo_rel: null,
            empreendtransf_obs: this.nova_transf.empreendtransf_obs,
            empreendtransf_taxa_vl: null,
            empreendtransf_taxa_dt: null,
            empreendtransf_taxa_pgto_vl: null,
            empreendtransf_taxa_pgto_dt: null,
            empreendtransf_doc_situacao: retorno.empreendtransf_doc_situacao,
            empreendtransf_cessao_numero: retorno.empreendtransf_cessao_numero,
            empreendtransf_cessao: retorno.empreendtransf_cessao,
            empreendtransf_acerto_vl: Number(this.nova_transf.empreendtransf_acerto_vl).toFixed(2),
            cod_empreendvendatransf_chave: retorno.cod_empreendvendatransf_chave,
            transferencia : []
          }
          this.store_Proposta.proposta_selecionado.emp_ve_transf.unshift(primeira_posicao_array)
          this.store_Proposta.proposta_selecionado.pessoa_venda.pessoa_nome = this.nova_transf.cessionario
          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
        this.dialog_novo = false

      }
    },

    novo(){
      this.selected = []
      this.nova_transf.cedente = this.store_Proposta.proposta_selecionado.pessoa_venda.pessoa_nome
      this.nova_transf.cod_pessoa_cedente = this.store_Proposta.proposta_selecionado.pessoa_venda.cod_pessoa
      this.dialog_novo = true
    },

    async montarModelo(){
      const lb_valido = this.$refs.formModelo.validate();

      if (lb_valido){ 
        var lo_JSON = {
          cod_empreendvenda             : this.$route.params.cod_empreendvenda,
          tipo_modelo                   : this.modelo_escolhido.tipo,
          cod_empresa                   : this.modelo_escolhido.cod_empresa,
          filename                      : this.modelo_escolhido.filename,
          cod_empreendvendatransf_chave : this.selected.cod_empreendvendatransf_chave,
          cod_editor_documento          : this.selected?.transferencia[0]?.cod_editor_documento ?? null
        };
      
        var ls_Res;
        this.loading = true;
        let cod_editor_documento = null;
        if (this.selected.transferencia.length > 0) {
          cod_editor_documento = this.selected.transferencia[0].cod_editor_documento;
        }
        else 
        {
          ls_Res = await this.store_Proposta.ModeloPost(lo_JSON);
          if (ls_Res.result.message != 'success'){
            this.store_site.alert_cor = "#A00000";
            this.store_site.alert_timeout = 20000;
            this.store_site.alert_msg = ls_Res.errors.trim();
            this.store_site.alert = true;
            this.dialog_modelo = false

            var lo_params = {
              cod_empreendvenda: this.$route.params.cod_empreendvenda
            };
            if (lo_params.cod_empreendvenda) {
              this.store_Proposta.proposta_selecionado = {};
              this.store_Proposta.acao = "C";
              var lo_Res = await this.store_Proposta.PropostaGet(lo_params);
            }
            if (!!lo_Res) {
              this.store_Proposta.proposta_selecionado = { ...lo_Res };
            }
          }
          
          cod_editor_documento = ls_Res.result.data.cod_editor_documento;
        }

        //neste trecho estou fazendo a copia do arquivo modelo para a pasta correspondente
        let empresa = String(this.store_site.cod_empresa).padStart(6, 0)
        const param_modelo_arquivo = `editor_documentos/empresa_${empresa}/empreend_modelo/${this.modelo_escolhido.tipo}/${this.modelo_escolhido.filename}`
        const param_output_caminho = `editor_documentos/empresa_${empresa}/empreend_venda/${this.modelo_escolhido.tipo}`
        const param_output_arquivo = `empreend_venda_${this.modelo_escolhido.tipo}_${empresa}_${String(this.$route.params.cod_empreendvenda).padStart(6, 0)}_${String(cod_editor_documento).padStart(6, 0)}.docx`
        
        const lo_params_docx = {
          modelo_arquivo : param_modelo_arquivo,
          output_caminho : param_output_caminho,
          output_arquivo : param_output_arquivo
        }
        // cria uma cópia do modelo para o lugar correto e com o nome correto
        const ls_ResDOCX =  await API.post('/mescla_docx', JSON.stringify(lo_params_docx))
        
        // nomeando as variaveis para serem enviadas ao componente
        this.TITULO = param_output_arquivo
        this.ARQUIVO = param_output_arquivo
        this.store_Proposta.proposta_selecionado.emp_ve_transf[0].transferencia.filename = param_output_arquivo

        let json_update = {
          cod_empreendvenda    : Number(this.$route.params.cod_empreendvenda),
          filename             : param_output_arquivo,
          cod_editor_documento : cod_editor_documento ? cod_editor_documento : this.selected.transferencia[0].cod_editor_documento,
          conteudo             : ls_ResDOCX.data.result.data,
          ultima_alteracao_dt  : moment().format()
        }

        const array_atualizado = this.selected.transferencia[0]
        this.selected.transferencia = []

        this.$nextTick( () => {
          this.selected.transferencia.push(array_atualizado)
        })

        // atualiza o banco com o conteudo correto e a ultima data de alteração
        ls_Res = await this.store_Proposta.ModeloPut(json_update);
        
        const elementoEncontrado = this.store_Proposta.proposta_selecionado.emp_ve_transf.find(
          elemento => elemento.cod_empreendvendatransf_chave === this.selected.cod_empreendvendatransf_chave
        );

        if (elementoEncontrado !== undefined) {
          const indice = this.store_Proposta.proposta_selecionado.emp_ve_transf.indexOf(elementoEncontrado);
          this.store_Proposta.proposta_selecionado.emp_ve_transf[indice].transferencia = [] 
          this.store_Proposta.proposta_selecionado.emp_ve_transf[indice].transferencia.push(ls_Res.result.data) 
        }
        // fim da cópia do arquivo

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;
          
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
        this.dialog_modelo = false
      }
    },
    
    async modelosGet(){
      const p_param = { tipo : 'transferencia', cod_empreendimento: this.store_Proposta.proposta_selecionado.cod_empreendimento } 
      const resposta = await API.get("modelo_documento", {params: p_param});
      if (resposta.data.result == "Não possui dados") {
        this.array_modelos = []
      }
      else{
        this.array_modelos =  resposta.data.result
      }
    },

    mostrarBotaoEditar() {
      if (!this.selected) {
        return false;
      }
      const array = this.store_Proposta.proposta_selecionado.emp_ve_transf
      const maiorNumero = Math.max(...array.map(item => item.cod_empreendvenda_transf));
      return  maiorNumero;
    },

    selecionarCedente(elem) {
      // este método seleciona a pessoa
      this.selected.cedente = elem.pessoa_nome
      this.selected.cod_pessoa_cedente = elem.cod_pessoa
     
      this.dialogCedente = false
    },
    
    selecionarNovoCedente(elem) {
      // este método seleciona a pessoa
      this.nova_transf.cedente = elem.pessoa_nome
      this.nova_transf.cod_pessoa_cedente = elem.cod_pessoa
     
      this.dialogNovoCedente = false
    },

    selecionarCessionario(elem) {
      // este método seleciona a pessoa
      this.selected.cessionario = elem.pessoa_nome
      this.selected.cod_pessoa_cessionario = elem.cod_pessoa
     
      this.dialogCessionario = false
    },

    selecionarNovoCessionario(elem) {
      // este método seleciona a pessoa
      this.nova_transf.cessionario = elem.pessoa_nome
      this.nova_transf.cod_pessoa_cessionario = elem.cod_pessoa
     
      this.dialogNovoCessionario = false
    },

    async abreDialogoCedente(){
      if (this.store_Proposta.acao !== 'C') {
        this.dialogCedente = true
      }
    },
    
    abreDialogoNovoCedente(){
      this.dialogNovoCedente = true
    },

    async abreDialogoCessionario(){
      if (this.store_Proposta.acao !== 'C') {
        this.dialogCessionario = true
      }
    },
    
    abreDialogoNovoCessionario(){
      this.dialogNovoCessionario = true
    },

    cancelar(){
      if (this.store_Proposta.acao != 'I') {
        const lAux = this.store_Proposta.proposta_selecionado.emp_ve_transf.findIndex((o) => o.cod_empreendvenda_transf = this.edicao_aux)
        this.store_Proposta.proposta_selecionado.emp_ve_transf[lAux] = this.edicao_aux;
        this.selected = this.store_Proposta.proposta_selecionado.emp_ve_transf[lAux];
      }

      this.store_Proposta.acao = 'C';
    },

    async transfAux(){
      this.store_Proposta.acao = 'E'; 
      this.edicao_aux = JSON.parse(JSON.stringify(this.selected));
    },

    formatItemText(item) {
      return `${item.cod_empreendvenda_transf} - ${item.empreendtransf_cessao} - ${item.cedente} - ${item.cessionario}`;
    },
  }
};
</script>

<style scoped>
#TransferenciaCriacao {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #TransferenciaCriacao {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#TransferenciaCriacao::-webkit-scrollbar {
  width: 5px;
}

#TransferenciaCriacao::-webkit-scrollbar-button {
  padding: 1px;
}

#TransferenciaCriacao::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#TransferenciaCriacao::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #TransferenciaCriacao::-webkit-scrollbar {
    width: initial;
  }

  #TransferenciaCriacao::-webkit-scrollbar-button {
    padding: initial;
  }

  #TransferenciaCriacao::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #TransferenciaCriacao::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

#myBtn {
  position: fixed;
  border-radius: 50px;
  bottom: 15px;
  right: 0;
  z-index: 99;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
  min-width: 40px;
  width: 45px;
  height: 43px;
  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;
}
.title-page {
  font-family: "Open Sans", sans-serif !important;
  letter-spacing: 0px;
  font-size: 18px;
}

.container {
  max-width: 100%;
  height: calc(100% - 46px);
}
</style>
