<template>
  <div id="RenegociacaoCriacaoModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Criar Renegociação</span>
          <v-btn
          @click="store_Proposta.dialogRenegociacaoCriacao = false"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
              ref="form"
              v-model="valid">

                <v-select
                v-model="dados.acordo"
                label="Acordo selecionado"
                background-color="#FFF"
                class="mb-0"
                dense
                filled
                required
                :items="acordo_selecionado"
                :rules="acordoRules"
                ></v-select>

                <v-text-field
                v-model="dados.numero_acordo"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Número do acordo"
                placeholder="Número do acordo"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-menu
                ref="menuRenegociacao_dt"
                v-model="menuRenegociacao_dt"
                :close-on-content-click="false"
                :return-value.sync="dados.renegociacao_dt"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                  class="mt-n2"
                  v-model="renegociacao_dt"
                  background-color="#FFF"
                  label="Data"
                  append-icon="mdi-calendar"
                  filled
                  dense
                  v-bind="attrs"
                  v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="renegociacao_dt"
                  @input="menuRenegociacao_dt = false"
                ></v-date-picker>
                </v-menu>

                <v-text-field
                v-model="dados.tipo"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Tipo de acordo"
                placeholder="Tipo de acordo"
                filled
                required
                dense
                :rules="tipoRules"
                ></v-text-field>

                <v-textarea
                v-model="dados.descricao"
                rows="3"
                class="mt-n2"
                background-color="#FFF"
                label="Descrição"
                placeholder="Descrição"
                filled
                dense
                :rules="descricaoRules"
                ></v-textarea>

                <v-select
                label="Minuta"
                v-model="dados.minuta"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="modelo_minuta"
                item-text="name"
                :rules="minutaRules"
                ></v-select>

                <v-text-field
                v-model="dados.juros"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Juros (% ao dia)"
                placeholder="Juros (% ao dia)"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-text-field
                v-model="dados.multa"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Multa (%)"
                placeholder="Multa (%)"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-select
                label="Cobrar juros TJ"
                v-model="dados.cobrar_juros"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="modelo_juros"
                item-text="name"
                :rules="jurosRules"
                ></v-select>

                <v-text-field
                v-model="dados.honorarios"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Honorários"
                placeholder="Honorários"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-text-field
                v-model="dados.taxas"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Taxas extras"
                placeholder="Taxas extras"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-text-field
                v-model="dados.outras_custas"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Outras custas"
                placeholder="Outras custas"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-text-field
                v-model="dados.desconto"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Desconto"
                placeholder="Desconto"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Proposta.dialogRenegociacaoCriacao = false"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import { COR_PRINCIPAL } from "../../../services/constantes";
import store_Proposta from "../store_Proposta";

export default {
  name: "RenegociacaoCriacaoModal",

  components: {
  },

  data() {
    return {

      COR_PRINCIPAL     : COR_PRINCIPAL,
      store_Proposta      : store_Proposta,

      dados: {
        acordo: null,
        numero_acordo: "",
        tipo: "",
        descricao: "",
        minuta: null,
        juros: "",
        multa: "",
        cobrar_juros: null,
        honorarios: "",
        taxas: "",
        outras_custas: "",
        desconto: "",
      },

      /* ACORDO SELECIONADO */
      acordo_selecionado: [
        "Acordo 01", "Acordo 02", "Acordo 03"
      ],

      /* MINUTA */
      modelo_minuta: [
        "Teste 01", "Teste 02", "Teste 03"
      ],

      /* COBRAR JUROS TJ */
      modelo_juros: [
        "Teste 01", "Teste 02", "Teste 03"
      ],

      /* Inputs e Menus Data */
      renegociacao_dt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuRenegociacao_dt: false,

      valid: true,
      form: false,

      numeroRules: [
        (value) => !!value || "O número é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      tipoRules: [
        (value) => !!value || "O tipo é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O tipo deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O tipo deve ter menos do que 60 caracteres",
      ],
      descricaoRules: [
        (value) => !!value || "A descrição é obrigatória",
        (value) =>
          (value && value.length >= 3) ||
          "A descrição deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 255) ||
          "A descrição deve ter menos do que 255 caracteres",
      ],
      acordoRules: [
        (value) => !!value || "O acordo é obrigatório",
      ],
      minutaRules: [
        (value) => !!value || "A minuta é obrigatória",
      ],
      jurosRules: [
        (value) => !!value || "Cobrar juros é obrigatório",
      ],
    };
  },

  methods: {
    validate () {
      this.$refs.form.validate()
      this.store_Proposta.docRenegociacao = 1;
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 460px;
  overflow-y: auto;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.btn {
  width: 100px;
}
</style>