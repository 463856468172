<template>
  <div id="TransferenciaRemontagem">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
      Transferências
      </p>
      <v-btn 
        @click="store_Proposta.dialogTransferenciaCriacao = true" 
        class="btn mr-1 pr-6 " elevation="0">
        <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
        <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Novo</span>
      </v-btn>

    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <!-- TABELA TRANSFERENCIAS -------------------------------------------------------->
    <v-data-table
    class="grade mt-n1 mb-4 rounded-lg tabela"
    :items="dados_transferencia"
    :headers="headers_transferencia"
    :loading="loading"
    :items-per-page="10"
    hide-default-header
    hide-default-footer
    loading-text="Carregando...  aguarde..."
    no-data-text="Nenhum Registro Encontrado"
    no-results-text="Nenhum Registro Encontrado"
    >
      <template v-slot:header>
          <thead>
            <tr>
              <th v-for="h in headers_transferencia" :key="h.value" :class="h.class" class="py-2">
                <span>{{h.text}}</span>
              </th>
            </tr>
          </thead>
      </template>

      <template #item="{ item }">
        <tr>
          <td>
            <v-menu
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="grey"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list class="py-0">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  @click="() => {}"
                  class="px-2"
                >
                  <v-list-item-title
                    v-if="item.title == 'Editar'"
                    @click="store_Proposta.dialogTransferenciaCriacao = true"  
                  >
                    <v-icon color="green" class="mr-2 icon-menu">
                      mdi-pencil
                    </v-icon>
                    {{ item.title }}
                  </v-list-item-title>

                  <v-list-item-title
                    v-else-if="item.title == 'Excluir'"
                    ><v-icon color="red" class="mr-2 icon-menu"
                      >mdi-delete</v-icon
                    >
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>

          <td>
            {{ item.numero }}
          </td>

          <td>
            {{ item.data }}
          </td>

          <td>
            {{ item.cessao }}
          </td>

          <td>
            {{ item.cedente }}
          </td>

          <td>
            {{ item.cessionario }}
          </td>

          <td>
            {{ item.valor }}
          </td>

          <td>
            {{ item.docs }}
          </td>

          <td>
            {{ item.obs }}
          </td>
        </tr> 
      </template>
    </v-data-table>

    <v-dialog
      v-model="store_Proposta.dialogTransferenciaCriacao"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
      <TransferenciaCriacaoModal />
    </v-dialog>
    <!-- FIM TABELA TRANSFERENCIAS -------------------------------------------------------->   

    <!-- CONTAINER EDITOR -------------------------------------------------------->
    <v-container class="grademx-0 pa-0 container">
      <v-card elevation="0" class="card rounded-lg mx-0 px-0">
        <template>
          <v-toolbar
          flat
          class="grade rounded-lg"
          >

            <p class="font-primary font-weight-bold body-1 text-center mt-1" v-bind:style="{ 'color':COR_SUBTITULO }">
              Documento de Transferência
            </p>  

            <v-spacer></v-spacer>

            <!-- MODELO DE Transferencia -------------------->
            <v-col cols="12" sm="4" class="mt-10 mr-4 pt-0 px-0 ">
              <v-select
              label="Modelo de Transferencia"
              v-model="dados.modelo"
              background-color="#tran"
              solo
              dense
              flat
              :items="modelo_transferencia"
              item-text="name"
              ></v-select>
            </v-col> 
            <!-- MODELO DE Transferencia -------------------->

            <v-btn 
              class="mr-4 pr-6" elevation="0">
              <v-icon class="btn-icon mr-1" v-bind:style="{ 'color':COR_SUBTITULO }">mdi-sync-circle</v-icon>
              <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Remontar Transferencia</span>
            </v-btn>

            <v-btn 
            class="pr-6" elevation="0">
              <v-icon class="btn-icon mr-1" v-bind:style="{ 'color':COR_SUBTITULO }">mdi-printer</v-icon>
              <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Imprimir</span>
            </v-btn>

            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <!-- EDITOR -------------------------------------------------------->
        <v-card-text class="grade">
            <div class="grade" style="width: 100%; height: 400px;">
            <iframe frameBorder="0" width='100%' height='1000' src="https://docs.google.com/document/d/1KdFYg7C3qIAZKW-qnmLfpHyll6U7JUPqt0yP6G63pUM/edit#heading=h.6jynaot9cbnq/pub?embedded=true"></iframe>            
            <!-- https://codepen.io/Eazymov/full/MEzGYv/ -->
            <!-- https://openbase.com/js/vue2-editor -->
            <!-- https://github.surmon.me/vue-quill-editor/ -->
            <!-- https://openbase.com/js/@toast-ui/vue-editor#-examples -->
            <!-- http://hifarer.github.io/vueditor/ -->
            <!-- https://donotebase.github.io/quasar-tiptap/examples/all -->
            <!-- https://openbase.com/js/mavon-editor -->
            </div>
        </v-card-text>
        <!-- FIM EDITOR -------------------------------------------------------->
      </v-card>
    </v-container>
    <!-- FIM CONTAINER EDITOR -------------------------------------------------------->

    <v-divider class="divider mt-3"></v-divider>

    <v-footer color="transparent" elevation="0" class="footer justify-center pt-3">
        <v-btn
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

      <v-btn
      :disabled="!valid"
      @click="validate()"
      class="btn caption font-weight-medium white--text"
      color="primary accent-4"
      >
        Salvar
      </v-btn>
    </v-footer>   

  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import { COR_SUBTITULO } from "../../../services/constantes";
import TransferenciaCriacaoModal from "../Transferencia/TransferenciaCriacaoModal.vue";

export default {
  name: "TransferenciaRemontagem",

  components: {
    TransferenciaCriacaoModal,
  },

  data() {
    return {

      store_Proposta  : store_Proposta,
      COR_SUBTITULO     : COR_SUBTITULO,  
      
      valid: true,
      loading: false,

      dados: {
        modelo: null,
      },

      /* TITULOS TABELA TRANSFERENCIAS */
      headers_transferencia: [
        { text: "" },
        { text: "Número", value: "Número", class: 'title-text' },
        { text: "Data", value: "Data", class: 'title-text' },
        { text: "Cessão", value: "Cessão", class: 'title-text' },
        { text: "Cedente", value: "Cedente", class: 'title-text' },
        { text: "Cessionário", value: "Cessionário", class: 'title-text' },
        { text: "Valor", value: "Valor", class: 'title-text' },
        { text: "Docs. Ordem", value: "Docs Ordem", class: 'title-text' },
        { text: "Observações", value: "Observações", class: 'title-text' },
      ],

      /* TABELA TRANSFERENCIAS */
      dados_transferencia: [
        {numero: "0001", data: "21/01/2022", cessao: "02", cedente: "Fernando Garcia", cessionario: "Bruno Duarte", valor: "50.000,00", docs: "Sim", obs: "Nenhuma"},
        {numero: "0002", data: "21/01/2022", cessao: "03", cedente: "Fernando Garcia", cessionario: "João Feliciano", valor: "40.000,00", docs: "Sim", obs: "Nenhuma"},
      ],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],

    /* SELECT SITUACAO */
      modelo_transferencia: [
        "Transferencia - Santa Lucia (modelo 1)",
      ],
    };
  },
};
</script>

<style scoped>
#TransferenciaRemontagem {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #TransferenciaRemontagem {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#TransferenciaRemontagem::-webkit-scrollbar {
  width: 5px;
}

#TransferenciaRemontagem::-webkit-scrollbar-button {
  padding: 1px;
}

#TransferenciaRemontagem::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#TransferenciaRemontagem::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #TransferenciaRemontagem::-webkit-scrollbar {
    width: initial;
  }

  #TransferenciaRemontagem::-webkit-scrollbar-button {
    padding: initial;
  }

  #TransferenciaRemontagem::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #TransferenciaRemontagem::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
}

.card {
  box-shadow: var(--COR_SOMBRA)!important;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.divider {
  margin-bottom: 2px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}

.grade {
  background-color: #F3F3F3!important;
  color: #404040!important;
}

</style>
