<template>
  <div id="RenegociacaoRemontagem">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2 mb-4">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
        Renegociação
      </p>

      <v-btn 
      class="btn mr-1 pr-6 " elevation="0">
        <v-icon class="btn-icon mr-1" color="green accent-4">mdi-plus</v-icon>
        <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Novo</span>
      </v-btn>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <!-------- FORM RENEGOCIACAO ----------------------------->
    <v-form
    v-model="valid"
    ref="form"
    class="form mx-1"
    >
      <v-row>
        <v-col cols="12" sm="6" class="mr-2 px-2 pb-0">
          <v-select
          v-model="dados.acordo"
          label="Acordo selecionado"
          background-color="#F7F7F7"
          class="mt-n3"
          solo
          dense
          flat
          :items="acordo_selecionado"
          ></v-select>
        </v-col>

        <v-btn 
        class="mr-4 pr-6" elevation="0">
          <v-icon class="btn-icon mr-1" color="green accent-4">mdi-pencil-outline</v-icon>
          <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Editar</span>
        </v-btn>

        <v-btn 
        class="pr-6" elevation="0">
          <v-icon class="btn-icon mr-1" color="red">mdi-delete-outline</v-icon>
          <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Excluir</span>
        </v-btn>
      </v-row>

      <v-col class="pa-0 pt-2">
        <div>
          <span class="header-text body-2 font-weight-medium mr-4">Nº acordo <span class="font-weight-bold body-1" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.acordo }}</span></span>
          <span class="header-text body-2 font-weight-medium mr-4">Data: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.data }}</span></span>
          <span class="header-text mr-4">Tipo de acordo: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.tipo }}</span></span>
          <span class="header-text mr-4">Descrição: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.descricao }}</span></span>
          <span class="header-text">Minuta: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.descricao }}</span></span>
        </div>

        <div>
          <span class="header-text mr-4">Juros (% ao dia): <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.juros }}</span></span>
          <span class="header-text mr-4">Multa (%): <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.multa }}</span></span>
          <span class="header-text mr-4">Cobrar juros TJ: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{dados.cobrar_juros}}</span></span>
          <span class="header-text mr-4">Honorários: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.honorarios }}</span></span>
          <span class="header-text mr-4">Taxas extras: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ dados.taxas }}</span></span>
          <span class="header-text mr-4">Outras custas: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{dados.outras_custas}}</span></span>
          <span class="header-text mr-4">Desconto: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{dados.desconto}}</span></span>
        </div>
      </v-col>
    </v-form>  

    <v-row class="mt-4 pa-0">
      <v-col cols="7" class="pr-1 pl-3">
        <v-card elevation="0" class="grade rounded-lg tabela">
          <!-- TABELA PARCELAS ATRASADAS -------------------------------------------------------->
          <v-data-table
          class="mb-4 grade"
          :items="dados_parc_atrasadas"
          :headers="headers_parc_atrasadas"
          :loading="loading"
          :items-per-page="10"
          hide-default-header
          hide-default-footer
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
          >
            <template v-slot:top>
              <v-toolbar
              flat
              class="grade mb-n2 rounded-lg"
              >
                <p class="font-primary font-weight-bold body-1 text-center mt-1" v-bind:style="{ 'color':COR_SUBTITULO }">
                  Parcelas Atrasadas/ Negociadas
                </p>  

                <v-spacer></v-spacer>

                <v-btn
                color="grey"
                dark
                icon
                >
                  <v-icon color="green accent-4">mdi-checkbox-marked-outline</v-icon>
                </v-btn>

                <v-btn
                color="grey"
                dark
                icon
                >
                  <v-icon color="red">mdi-delete-outline</v-icon>
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:header>
                <thead>
                  <tr>
                    <th v-for="h in headers_parc_atrasadas" :key="h.value" :class="h.class" class="py-2">
                      <span>{{h.text}}</span>
                    </th>
                  </tr>
                </thead>
            </template>

            <template #item="{ item }">
              <tr>
                <td>
                  {{ item.parcela }}
                </td>

                <td>
                  {{ item.via }}
                </td>

                <td>
                  {{ item.vencimento }}
                </td>

                <td>
                  {{ item.valor }}
                </td>

                <td>
                  {{ item.indice }}
                </td>

                <td>
                  {{ item.dias_atraso }}
                </td>

                <td>
                  {{ item.juros}}
                </td>

                <td>
                  {{ item.multa}}
                </td>

                <td>
                  {{ item.valor_final}}
                </td>
              </tr> 
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="5" class="pl-1">
        <v-card elevation="0" class="grade rounded-lg tabela">
          <!-- TABELA PARCELAS NOVA NEGOCIACAO -------------------------------------------------------->
          <v-data-table
          class="grade mb-4"
          :items="dados_parc_renegociacao"
          :headers="headers_parc_renegociacao"
          :loading="loading"
          :items-per-page="10"
          hide-default-header
          hide-default-footer
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
          >
            <template v-slot:top>
              <v-toolbar
              flat
              class="grade mb-n2 rounded-lg"
              >
                <p class="font-primary font-weight-bold body-1 text-center mt-1" v-bind:style="{ 'color':COR_SUBTITULO }">
                  Parcelas da nova renegociação
                </p>  

                <v-spacer></v-spacer>

                <v-btn
                color="grey"
                dark
                icon
                >
                  <v-icon class="btn-icon" color="green accent-4">mdi-checkbox-marked-outline</v-icon>
                </v-btn>

                <v-btn
                color="grey"
                dark
                icon
                >
                  <v-icon class="btn-icon" color="red">mdi-delete-outline</v-icon>
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:header>
                <thead>
                  <tr>
                    <th v-for="h in headers_parc_renegociacao" :key="h.value" :class="h.class" class="py-2">
                      <span>{{h.text}}</span>
                    </th>
                  </tr>
                </thead>
            </template>

            <template #item="{ item }">
              <tr>
                <td>
                  {{ item.situacao }}
                </td>

                <td>
                  {{ item.parcela }}
                </td>

                <td>
                  {{ item.vencimento }}
                </td>

                <td>
                  {{ item.valor }}
                </td>
              </tr> 
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- FIM FORM RENEGOCIACAO -->    

    <!-- CONTAINER EDITOR -------------------------------------------------------->
    <v-container class="mt-4 mx-0 pa-0 container">
        <v-card elevation="0" class="grade rounded-lg tabela">
        <template>
          <v-toolbar
          flat
          class="grade rounded-lg"
          >
            <p class="font-primary font-weight-bold body-1 text-center mt-1" v-bind:style="{ 'color':COR_SUBTITULO }">
              Documento de Renegociação
            </p>  

            <v-spacer></v-spacer>

            <!-- MODELO DE RESCISAO -------------------->
            <v-col cols="12" sm="4" class="mt-10 mr-4 pt-0 px-0 ">
              <v-select
              label="Modelo da renegociação"
              v-model="dados.modelo"
              background-color="#F3F3F3"
              solo
              dense
              flat
              :items="modelo_renegociacao"
              item-text="name"
              ></v-select>
            </v-col> 
            <!-- MODELO DE RESCISAO -------------------->

            <v-btn 
              class="mr-4 pr-6" elevation="0">
              <v-icon class="btn-icon mr-1" v-bind:style="{ 'color':COR_SUBTITULO }">mdi-autorenew</v-icon>
              <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Remontar Renegociação</span>
            </v-btn>

            <v-btn 
            class="pr-6" elevation="0">
              <v-icon class="btn-icon mr-1" v-bind:style="{ 'color':COR_SUBTITULO }">mdi-printer-outline</v-icon>
              <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Imprimir</span>
            </v-btn>

            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <!-- EDITOR -------------------------------------------------------->
        <v-card-text style="width: 100%; height: 700px;">
            <div style="width: 100%; height: 400px;">
            <iframe frameBorder="0" width='100%' height='670' src="https://docs.google.com/document/d/1KdFYg7C3qIAZKW-qnmLfpHyll6U7JUPqt0yP6G63pUM/edit#heading=h.6jynaot9cbnq/pub?embedded=true"></iframe>            
            <!-- https://codepen.io/Eazymov/full/MEzGYv/ -->
            <!-- https://openbase.com/js/vue2-editor -->
            <!-- https://github.surmon.me/vue-quill-editor/ -->
            <!-- https://openbase.com/js/@toast-ui/vue-editor#-examples -->
            <!-- http://hifarer.github.io/vueditor/ -->
            <!-- https://donotebase.github.io/quasar-tiptap/examples/all -->
            <!-- https://openbase.com/js/mavon-editor -->
            </div>
        </v-card-text>
        <!-- FIM EDITOR -------------------------------------------------------->
      </v-card>
    </v-container>
    <!-- FIM CONTAINER EDITOR -------------------------------------------------------->
  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import { COR_SUBTITULO } from "../../../services/constantes";

export default {
  name: "RenegociacaoRemontagem",

  components: {
  },

  data() {
    return {

      store_Proposta    : store_Proposta,
      COR_SUBTITULO     : COR_SUBTITULO,  
      
      valid: true,
      loading: false,

      dados:
        {
          acordo: null,
          data: "35.145,60",
          tipo: "35.145,60",
          descricao: "35.145,60",
          minuta: "",
          jutos: "",
          multa: "",
          cobrar_juros: "",
          honorarios: "",
          taxas: "",
          outras_custas: "",
          desconto: "",
        },

      /* SELECT ACORDO */
      acordo_selecionado: [
        "Teste1", "Teste2", "Teste3",
      ],  

      /* SELECT MODELO */
      modelo_renegociacao: [
        "Modelo de Renegociação - Santa Lucia (modelo 1)",
      ],

      /* TITULOS TABELA ATRASADAS/NEGOCIADAS */
      headers_parc_atrasadas: [
        { text: "Parc.", value: "Parc.", class: 'title-text' },
        { text: "Via", value: "Via", class: 'title-text' },
        { text: "Vencto.", value: "Vencto.", class: 'title-text' },
        { text: "Valor", value: "Valor", class: 'title-text' },
        { text: "Índice(%)", value: "Índice(%)", class: 'title-text' },
        { text: "Dias Atr.", value: "Dias Atr.", class: 'title-text' },
        { text: "Juros", value: "Juros", class: 'title-text' },
        { text: "Multa", value: "Multa", class: 'title-text' },
        { text: "Vl. Final", value: "Vl. Final", class: 'title-text' },
      ],

      /* TABELA ATRASADAS/NEGOCIADAS */
      dados_parc_atrasadas: [
          {
            parcela: "Atrasado", 
            via: "0", 
            vencimento: "05/03/2022", 
            valor: "3.333,00", 
            indice: "", 
            dias_atraso: "3.333,00",
            juros: "",
            multa: "04/01/2022",
            valor_final: "82.000,00",
          },
          {
            parcela: "Atrasado", 
            via: "0", 
            vencimento: "05/03/2022", 
            valor: "450,56", 
            indice: "", 
            dias_atraso: "450,56",
            juros: "",
            multa: "04/01/2022",
            valor_final: "82.000,00",
          },
          {
            parcela: "Dia", 
            via: "0", 
            vencimento: "05/03/2022", 
            valor: "12.455,00", 
            indice: "", 
            dias_atraso: "12.455,00",
            juros: "",
            multa: "04/01/2022",
            valor_final: "82.000,00",
          },          
          {
            parcela: "Dia", 
            via: "0", 
            vencimento: "05/03/2022", 
            valor: "2.033,45", 
            indice: "", 
            dias_atraso: "3.455,34",
            juros: "",
            multa: "04/01/2022",
            valor_final: "82.000,00",
          },          
      ],

      /* TITULOS TABELA PARCELAS */
      headers_parc_renegociacao: [
        { text: "Situação", value: "Situação", class: 'title-text' },
        { text: "Parcela", value: "Parcela", class: 'title-text' },
        { text: "Vencto.", value: "Vencto.", class: 'title-text' },
        { text: "Valor", value: "Valor", class: 'title-text' },
      ],

      /* TABELA PARCELAS */
      dados_parc_renegociacao: [
        {situacao: "Mensal", parcela: "48", vencimento: "10/04/2022", valor: "1.697,22" },
        {situacao: "Mensal", parcela: "48", vencimento: "10/04/2022", valor: "1.697,22" },
        {situacao: "Mensal", parcela: "48", vencimento: "10/04/2022", valor: "1.697,22" },
        {situacao: "Mensal", parcela: "48", vencimento: "10/04/2022", valor: "1.697,22" },
      ],
      
      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Imprimir boleto normal",
        },
        {
          title: "Imprimir boleto antecipação",
        },
        {
          title: "Aniversário / reajuste",
        },
        {
          title: "Alterar vencto parcelas",
        },
      ],

    };
  },

  methods: {
    validate () {
      this.$refs.form.validate()
    },
  },
};
</script>

<style scoped>
#RenegociacaoRemontagem {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #RenegociacaoRemontagem {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#RenegociacaoRemontagem::-webkit-scrollbar {
  width: 5px;
}

#RenegociacaoRemontagem::-webkit-scrollbar-button {
  padding: 1px;
}

#RenegociacaoRemontagem::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#RenegociacaoRemontagem::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #RenegociacaoRemontagem::-webkit-scrollbar {
    width: initial;
  }

  #RenegociacaoRemontagem::-webkit-scrollbar-button {
    padding: initial;
  }

  #RenegociacaoRemontagem::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #RenegociacaoRemontagem::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
}

.checkbox {
  margin-top: -3px;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.divider {
  margin-bottom: 2px;
}

.grade {
  background-color: #F3F3F3!important;
  color: #404040!important;
}

</style>
