import { render, staticRenderFns } from "./RescisaoRemontagem.vue?vue&type=template&id=69bb9b1a&scoped=true"
import script from "./RescisaoRemontagem.vue?vue&type=script&lang=js"
export * from "./RescisaoRemontagem.vue?vue&type=script&lang=js"
import style0 from "./RescisaoRemontagem.vue?vue&type=style&index=0&id=69bb9b1a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69bb9b1a",
  null
  
)

export default component.exports