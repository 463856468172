<template>
  <div id="TransferenciaCriacaoModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Criar Transferência</span>
          <v-btn
          @click="store_Proposta.dialogTransferenciaCriacao = false"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
              ref="form"
              v-model="valid">

                <v-text-field
                v-model="dados.numero_transferencia"
                class="mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Número da Transferência"
                placeholder="Número da Transferência"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-menu
                ref="menuTransferencia_dt"
                v-model="menuTransferencia_dt"
                :close-on-content-click="false"
                :return-value.sync="dados.transferencia_dt"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    class="mt-n2"
                    v-model="transferencia_dt"
                    background-color="#FFF"
                    label="Data do Transferencia"
                    append-icon="mdi-calendar"
                    filled
                    dense
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="transferencia_dt"
                    @input="menuTransferencia_dt = false"
                  ></v-date-picker>
                </v-menu>

                <v-text-field
                v-model="dados.numero_cessao"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Número da Cessão"
                placeholder="Número da Cessão"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-select
                label="Modelo de Transferência"
                v-model="dados.modelo"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="modelo_transferencia"
                item-text="name"
                :rules="modeloRules"
                ></v-select>

                <v-text-field
                v-model="dados.cedente"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Cedente"
                placeholder="Cedente"
                filled
                required
                dense
                :rules="cedenteRules"
                ></v-text-field>

                <v-autocomplete
                v-model="dados.cessionario"
                class="mt-n2 mb-0"
                background-color="#FFF"
                :items="nomes_cessionario"
                label="Cessionário"
                filled
                return-object
                required
                dense
                :rules="cessionarioRules"
                >
                  <template v-slot:append-outer>
                    <v-icon color="primary"
                    >mdi-plus-circle</v-icon>
                  </template>
                </v-autocomplete>

                <v-text-field
                v-model="dados.valor"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Valor do acerto"
                placeholder="Valor do acerto"
                filled
                required
                dense
                :rules="valorRules"
                ></v-text-field>

                <v-select
                label="Documentação está em ordem"
                v-model="dados.documentacao"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="documentacao_status"
                item-text="name"
                :rules="documentacaoRules"
                ></v-select>

                <v-textarea
                v-model="dados.observacoes"
                rows="3"
                class="mt-n2"
                background-color="#FFF"
                label="Observações"
                placeholder="Observações"
                filled
                dense
                ></v-textarea>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Proposta.dialogTransferenciaCriacao = false"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import { COR_PRINCIPAL } from "../../../services/constantes";
import store_Proposta from "../store_Proposta";

export default {
  name: "TransferenciaCriacaoModal",

  components: {
  },

  data() {
    return {

      COR_PRINCIPAL     : COR_PRINCIPAL,
      store_Proposta      : store_Proposta,

      dados: {
        numero_transferencia: "",
        numero_cessao: "",
        modelo: null,
        cedente: "",
        cessionario: null,
        valor: "",
        documentacao: null,
        observacoes: "",
      },

      /* Inputs e Menus Data */
      transferencia_dt: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuTransferencia_dt: false,

      /* CESSIONARIO */
      nomes_cessionario: [
         "Fernando Garcia", "Bruno Duarte", "João Feliciano" 
      ],

      /* SELECT MODELO */
      modelo_transferencia: [
        "Transferencia - Santa Lucia (modelo 1)",
      ],

      /* SELECT DOCUMENTACAO */
      documentacao_status: [
        "Transferencia - Santa Lucia (modelo 1)",
      ],

      valid: true,
      form: false,

      numeroRules: [
        (value) => !!value || "O número é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      cedenteRules: [
        (value) => !!value || "O cedente é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O cedente deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O cedente deve ter menos do que 60 caracteres",
      ],
      valorRules: [
        (value) => !!value || "O valor é obrigatório",
        (value) => /^\d+$/.test(value) || 'O valor não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O valor deve ter menos do que 10 caracteres",
      ],
      cessionarioRules: [
        (value) => !!value || "O cessionário é obrigatório",
      ],
      modeloRules: [
        (value) => !!value || "O modelo é obrigatório",
      ],
      documentacaoRules: [
        (value) => !!value || "A documentação é obrigatória",
      ],
    };
  },

  methods: {
    validate () {
      this.$refs.form.validate()
      this.store_Proposta.docTransferencia = 1;
      this.store_Proposta.dialogTransferenciaCriacao = false;
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 460px;
  overflow-y: auto;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.btn {
  width: 100px;
}
</style>