<template>
  <div id="RescisaoCriacao">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="body-1 font-primary ml-2 mb-4">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
      Rescisão
      </p>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <div class="container">
      <v-row class="mx-0">
        <v-col cols="12" class="d-flex flex-column">
          <span>
            Temporibus aspernatur et libero esse est voluptatem minima. Occaecati ratione quis maxime et et rerum. Eum animi ut dolore tenetur numquam numquam corrupti eligendi. Saepe dolore ex ea porro impedit mollitia iste qui. Quod eveniet labore.
          </span>
        </v-col>

        <v-col class="d-flex">
          <v-btn
          @click="store_Proposta.dialogRescisaoCriacao = true" 
          class="caption font-weight-medium white--text mx-auto"
          color="primary accent-4"
          >
            Criar Rescisão
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="store_Proposta.dialogRescisaoCriacao"
      transition="dialog-bottom-transition"
      max-width="400"
      class="pa-0"
    >
      <RescisaoCriacaoModal/>
    </v-dialog>
  </div>
</template>

<script>
import store_Proposta from "../store_Proposta";
import { COR_SUBTITULO } from "../../../services/constantes";
import RescisaoCriacaoModal from "../Rescisao/RescisaoCriacaoModal.vue";
import { API } from "../../../services/API"

export default {
  name: "RescisaoCriacao",

  components: {
    RescisaoCriacaoModal,
  },

  data() {
    return {

      store_Proposta  : store_Proposta,

      COR_SUBTITULO   : COR_SUBTITULO,  

    };
  },
};
</script>

<style scoped>
#RescisaoCriacao {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #RescisaoCriacao {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#RescisaoCriacao::-webkit-scrollbar {
  width: 5px;
}

#RescisaoCriacao::-webkit-scrollbar-button {
  padding: 1px;
}

#RescisaoCriacao::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#RescisaoCriacao::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #RescisaoCriacao::-webkit-scrollbar {
    width: initial;
  }

  #RescisaoCriacao::-webkit-scrollbar-button {
    padding: initial;
  }

  #RescisaoCriacao::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #RescisaoCriacao::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
  height: calc(100% - 46px);
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.divider {
  margin-bottom: 2px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}
</style>
